<template>
  <div class="gird">
    <Head :data="HeadSetting" />
    <section class="container">
      <storeHead :store="store" :imgs="imgs"/>
      <div class="itemBox" id="storeDetail">
        <h2 class="boxTitle">店舗詳細</h2>
        <div class="itemBoxInner">
          <dl class="storeDetailDl">
            <dt>
              <span class="icon"><font-awesome-icon icon="map-marker-alt" /></span>
              <span class="icontxt">住所</span>
            </dt>
            <dd>
              <span>{{store.fulladdress}}</span>
            </dd>
          </dl>
          <dl class="storeDetailDl">
            <dt>
              <span class="icon"><font-awesome-icon icon="phone" /></span>
              <span class="icontxt">電話番号</span>
            </dt>
            <dd>
              <span>{{store.tel}}</span>
            </dd>
          </dl>
          <dl class="storeDetailDl">
            <dt>
              <span class="icon"><font-awesome-icon icon="clock" /></span>
              <span class="icontxt">営業時間</span>
            </dt>
            <dd>
              <span>{{store.worktime_from}} 〜 {{store.worktime_to}}</span>
            </dd>
          </dl>
          <dl class="storeDetailDl" v-if="store.stations">
            <dt>
              <span class="icon"><font-awesome-icon icon="subway" /></span>
              <span class="icontxt">最寄駅</span>
            </dt>
            <dd>
              <ul>
                <li v-for="(station, index) in store.stations" :key="index">
                {{station.label}} <a :href="'http://maps.google.co.jp/maps?saddr=' + store.lat + ',' + store.lng + '&daddr=' + station.lat + ',' + station.lng + '&hl=ja&z=13&dirflg=w'" target="_blank"><span class="icon--direction"><font-awesome-icon icon="directions" /></span></a>
                </li>
              </ul>
            </dd>
          </dl>
          <p class="noticeTxt">※店舗情報は、{{store.updated_at_txt}}時点のデータになります。最新データでない可能性もありますので、ご了承ください。</p>
          <!-- <p>店舗情報の修正のご依頼</p> -->
        </div>
        <ul class="itemBoxBC">
          <li>
            <router-link :to="'/brand/' + store.brand_code + '/stores/'">{{store.brand_name_en}}の店舗</router-link>
          </li>
          <li>
            <router-link :to="'/brand/' + store.brand_code + '/stores/' + store.prefecture_code + '/'">{{store.prefecture_name_ja}}</router-link>
          </li>
          <li>
            <router-link :to="'/brand/' + store.brand_code + '/stores/' + store.prefecture_code + '/' + store.city_code + '/'">{{store.city_name_ja}}</router-link>
          </li>
        </ul>
      </div>
      <div v-if="getMapData" class="itemBox" id="storeMap">
        <h2 class="boxTitle">地図</h2>
        <Gmap :data="getMapData"/>
      </div>
      <div class="itemBox brandDetail">
        <h2 class="boxTitle">ブランド</h2>
        <div class="flex__center__box">
          <BrandLink :brand_code="store.brand_code" :brand_name_en="store.brand_name_en" :brand_name_ja="store.brand_name_ja"/>
          <BrandBtn :brand_id="store.brand_id" />
        </div>
      </div>
      <div v-if="citystores.length > 0" class="itemBox" id="storeRec">
        <h2 class="boxTitle">{{store.brand_name_en}}の{{store.prefecture_name_ja}}{{store.city_name_ja}}の店舗</h2>
        <storeList :lists="citystores" />
      </div>
      <div v-if="nearStore.length > 0" class="itemBox" id="storeRec">
        <h2 class="boxTitle">こちらのお店もオススメ</h2>
        <storeList :lists="nearStore"/>
      </div>
      <div class="itemBox" id="storeRec">
        <h2 class="boxTitle">{{store.brand_name_en}}のお店を探す</h2>
        <storePrefList :list="storeCountList" :path="storePath" />
      </div>
      <div class="itemBox">
        <p class="noticeTxt">※最寄り駅データはHeartRails Geo APIより提供されたデータを表示しております。 </p>
      </div>
    </section>
    <jsonStore :store="store" />
  </div>
</template>

<script>
import Head from "@/components/block/Head.vue";
import Gmap from "@/components/parts/Gmap.vue";
import storeHead from "@/components/parts/storeHead.vue";
import storeList from "@/components/parts/storeList.vue";
import storePrefList from "@/components/parts/storePrefList.vue";
import BrandLink from "@/components/parts/BrandLink.vue";
import BrandBtn from "@/components/parts/BrandBtn.vue";
import {Mixin} from '@/mixins/common'
import axios from 'axios';
import { mapActions } from "vuex"
import jsonStore from "@/components/parts/seo/store.vue";

export default {
  data() {
    return {
      store: {},
      map: false,
      HeadSetting: {
        Left: 'backBtn',
        Right: 'Share',
        Center: 'search'
      },
      loaded: false,
      imgs: {},
      citystores: {},
      storeCountList: {},
      storePath: '',
      nearStore: {},
      title: '',
      description: '',
    };
  },
  mixins:[Mixin],
  computed: {
    getMapData: function(){
      return this.map
    },
  },
  components: {
    Head,
    Gmap,
    storeHead,
    storeList,
    storePrefList,
    BrandLink,
    BrandBtn,
    jsonStore
  },
  beforeRouteUpdate(to, from, next) {
    if(to.name === from.name){
      window.scrollTo(0, 0);
    }
    this.pageInit(to)
    next()
  },
  created () {
    this.pageInit(this.$route)
  },
  mounted () {
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),
    refresh() {
      this.store = {}
      this.map = false,
      this.loaded = false,
      this.imgs = {},
      this.citystores = {}
      this.nearStore = {}
      this.title = ''
      this.description = ''
    },
    pageInit(route) {
      this.refresh()
      const id = route.params.id
      this.getData(id)
      this.getCityStores(id)
      this.getImgs(id)
      this.getStoreCountList(id)
      this.getNearStore(id)
    },
    getData(id){
      const url = this.api + '/stores/detail/' + id + '/data.json'

      axios.get(url)
      .then((res) => {
        if(res.data.ng) this.$router.push({name: 'NotFound'})
        this.store = res.data
        this.storePath = '/brand/' + this.store.brand_code + '/stores/'

        // map
        const tmpMap = {}
        tmpMap.center = {}
        tmpMap.center.lat = Number(this.store.lat)
        tmpMap.center.lng = Number(this.store.lng)
        tmpMap.title = this.store.brand_name_en + ' ' + this.store.name + 'の店舗マップ'
        this.map = tmpMap

        this.loaded = true

        // meta
        const title = this.store.brand_name_full + ' ' + this.store.name + '店'
        this.title = title
        this.description = title + '、店舗詳細ページ。お店までのルートや近くにあるおすすめ店舗を探すことができます。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = this.title
        shareData.text = this.title + 'の店舗情報ページ。'
        shareData.url = window.location.href
        this.setshareData(shareData)

      })
      .catch((e) =>{
        // console.log(e);
        // console.log('store data not found')
      })
    },
    async getImgs(id){
      const url = this.api + '/stores/detail/' + id + '/brandimgs'

      await axios.get(url)
      .then((res) => {
        this.imgs = res.data
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item imgs not found')
      })
    },
    async getCityStores(id){
      const url = this.api + '/stores/detail/' + id + '/citystores'

      // console.log(url);

      await axios.get(url)
      .then((res) => {
        this.citystores = res.data
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('CityStores not found')
      })
    },
    async getStoreCountList(id){
      const url = this.api + '/stores/detail/' + id + '/brandstores'

      await axios.get(url)
      .then((res) => {
        this.storeCountList = res.data.lists
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('brandstores not found')
      })
    },
    async getNearStore(id){
      const url = this.api + '/stores/detail/' + id + '/nearstores'

      // console.log(url);

      await axios.get(url)
      .then((res) => {
        this.nearStore = res.data
      })
      .catch((e) =>{
        // console.log(e);
        // console.log('near store not found')
      })
    },
  }
}
</script>

<style scoped>

.storeDetailDl{
  display: flex;
  align-items: center;
  margin-top: 0;
}

.storeDetailDl dt{
  min-width: 50px;
  text-align: center;
}

.storeDetailDl dt .icon{
  display: block;
  color: #4cb5f5;
  font-size: 1.6rem;
}

.storeDetailDl dt .icontxt{
  display: block;
  font-size: .7rem;
  color: #666;
}

.storeDetailDl dd{
  margin-left: 16px;
  font-size: .85rem;
}

.storeDetailDl dd ul{
  margin: 0;
  list-style: none;
  padding-left: 0;
  font-size: .8rem;
}

.storeDetailDl dd ul li{
  padding: 2px 0;
}

.icon--direction{
  width: 24px;
  height: 24px;
  font-size: 1rem;
  color: #4cb5f5;
  display: inline-flex;
  padding: 2px;
  border: 1px solid #DDD;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}
</style>
