<template functional>
  <div id="map">
    <GmapMap :center="props.data.center" :zoom="15" style="width: 100%; height: 100%;">
      <GmapMarker
        :position="props.data.center"
        :draggable="true"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>
<style scoped>
#map{
  height: 300px;
}
</style>
