var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"brandHead"},[_c('div',{staticClass:"brandThumbGallaryBox"},[_c('ul',{staticClass:"brandThumbGallary"},_vm._l((_vm.props.imgs),function(img,index){return _c('li',[_c('img',{attrs:{"src":img.img_url_min}})])}),0)]),_c('div',{staticClass:"brandHeadInner"},[_c('div',{staticClass:"brandHeadInnerBox"},[_c('p',{staticClass:"brandHeadName"},[_c('span',{staticClass:"store__brand__name"},[_vm._v(_vm._s(_vm.props.store.brand_name_en))]),_c('span',{staticClass:"store__name"},[_vm._v(_vm._s(_vm.props.store.name)+" 店")])]),_c('ul',{staticClass:"HeadMenus"},[_c('li',[_c('span',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
     el: '#storeDetail',
     container: 'body',
     duration: 300,
     easing: 'linear',
     offset: -48,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 }),expression:"{\n     el: '#storeDetail',\n     container: 'body',\n     duration: 300,\n     easing: 'linear',\n     offset: -48,\n     force: true,\n     cancelable: true,\n     onStart: false,\n     onDone: false,\n     onCancel: false,\n     x: false,\n     y: true\n }"}],staticClass:"iconLink"},[_c('span',{staticClass:"iconLinkIcon"},[_c('font-awesome-icon',{attrs:{"icon":"store"}})],1),_c('span',{staticClass:"icon__link__txt"},[_vm._v("店舗詳細")])])]),_c('li',[_c('span',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
     el: '#storeMap',
     container: 'body',
     duration: 300,
     easing: 'linear',
     offset: -48,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
 }),expression:"{\n     el: '#storeMap',\n     container: 'body',\n     duration: 300,\n     easing: 'linear',\n     offset: -48,\n     force: true,\n     cancelable: true,\n     onStart: false,\n     onDone: false,\n     onCancel: false,\n     x: false,\n     y: true\n }"}],staticClass:"iconLink"},[_c('span',{staticClass:"iconLinkIcon"},[_c('font-awesome-icon',{attrs:{"icon":"map-marked-alt"}})],1),_c('span',{staticClass:"icon__link__txt"},[_vm._v("地図")])])]),_c('li',[_c('router-link',{staticClass:"iconLink",attrs:{"to":'/brand/' + _vm.props.store.brand_code + '/items/'}},[_c('span',{staticClass:"iconLinkIcon"},[_c('font-awesome-icon',{attrs:{"icon":"tshirt"}})],1),_c('span',{staticClass:"icon__link__txt"},[_vm._v("アイテムを探す")])])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }