<template functional>
  <script type="application/ld+json">
  {
    "@context": "http://schema.org",
    "@type": "Store",
    "name": "{{props.store.brand_name_en}} {{props.store.name}}",
    "openingHours": "Mo-Su {{props.store.worktime_from}}-{{props.store.worktime_to}}",
    "telephone": "{{props.store.tel}}",
    "address":
    {
    "@type": "PostalAddress",
    "streetAddress": "{{props.store.address}}",
    "addressLocality": "{{props.store.city_name_ja}}",
    "addressRegion": "{{props.store.prefecture_name_ja}}",
    "addressCountry": "JPN"
    }
  }
  </script>
</template>

<script>
export default {
  props: ['store']
}
</script>
