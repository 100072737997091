<template functional>
  <div class="brandHead">
    <div class="brandThumbGallaryBox">
      <ul class="brandThumbGallary">
        <li v-for="(img, index) in props.imgs">
          <img :src="img.img_url_min" />
        </li>
      </ul>
    </div>
    <div class="brandHeadInner">
      <div class="brandHeadInnerBox">
        <p class="brandHeadName">
          <span class="store__brand__name">{{props.store.brand_name_en}}</span>
          <span class="store__name">{{props.store.name}} 店</span>
        </p>
        <ul class="HeadMenus">
          <li>
            <span class="iconLink" v-scroll-to="{
       el: '#storeDetail',
       container: 'body',
       duration: 300,
       easing: 'linear',
       offset: -48,
       force: true,
       cancelable: true,
       onStart: false,
       onDone: false,
       onCancel: false,
       x: false,
       y: true
   }">
              <span class="iconLinkIcon"><font-awesome-icon icon="store" /></span>
              <span class="icon__link__txt">店舗詳細</span>
            </span>
          </li>
          <li>
            <span class="iconLink" v-scroll-to="{
       el: '#storeMap',
       container: 'body',
       duration: 300,
       easing: 'linear',
       offset: -48,
       force: true,
       cancelable: true,
       onStart: false,
       onDone: false,
       onCancel: false,
       x: false,
       y: true
   }">
              <span class="iconLinkIcon"><font-awesome-icon icon="map-marked-alt" /></span>
              <span class="icon__link__txt">地図</span>
            </span>
          </li>
          <li>
            <router-link :to="'/brand/' + props.store.brand_code + '/items/'" class="iconLink">
              <span class="iconLinkIcon"><font-awesome-icon icon="tshirt" /></span>
              <span class="icon__link__txt">アイテムを探す</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['store', 'imgs'],
}
</script>

<style>
.brandHead{
  position: relative;
  margin-bottom: 100px;
}

.brandHeadInner{
  z-index: 2;
  bottom: -80px;
  position: absolute;
  width: 100%;
  padding: 16px;
}

.brandHeadInnerBox{
  background-color: rgba(255,255,255,.9);
  border-radius: 6px;
  padding: 16px;
  /* border: 1px solid #FAFAFA; */
  box-shadow:0px 1px 3px .5px #EEE;
}

.store__brand__name{
  font-size: .8rem;
  display: block;
  color: #999;
}

.store__name{
  font-size: 1rem;
  font-weight: bold;
  display: block;
  padding-bottom: 2px;
}


.brandHeadName{
  margin: 0 0 16px;;
  text-align: center;
}


.brandThumbGallaryBox{
 height: 180px;
 position: relative;
 display: flex;
 align-items: center;
 overflow: hidden;
 z-index: 1;
}

.brandThumbGallary{
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
}

.brandThumbGallaryBox:after{
  display: block;
  content: '';
  background: rgba(255,255,255,.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.brandThumbGallary li{
  min-width: 20%;
  max-width: 20%;
  height: 90px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.brandThumbGallary li img{
  width: 100%;
}


.HeadMenus{
  display: flex;
  list-style: none;
  padding-left: 0;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-evenly;
}
.HeadMenus li{
  min-width: 30%;
  max-width: 30%;
}

.icon__link__txt{
  font-size: .6rem;
}

</style>
